import React from 'react';

const LoadingSpinner = ({ message }) => {

  const h1Style = {
    fontFamily: "League Spartan",
    color: "#003C43",
  };
  return (
    <div className="spinner-container">
      <h1 style={h1Style}>Web Wizards Transpiling code from Javascript :D</h1>
      <div className="spinner"></div>
      {message && <p className="spinner-message">{message}</p>}
      <style jsx>{`
        .spinner-container {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          flex-direction: column;
        }
        .spinner {
          width: 30px;
          height: 30px;
          border: 3px solid #f3f3f3;
          border-top: 3px solid #003C43;
          border-radius: 50%;
          animation: spin 1s linear infinite;
          margin-right: 10px;
        }
        .spinner-message {
          font-family: 'League Spartan', sans-serif;
          color: #003C43;
        }
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

export default LoadingSpinner;